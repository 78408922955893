import 'summernote/dist/summernote';
import React, { Component } from 'react';
import jsBeautify from 'js-beautify';

import PropTypes from 'prop-types';
import $ from 'jquery';
import { frame } from './FramePlugin';
import { embedVideo } from './EmbedVideo';
import { insertImage } from './InsertImage';

/**
 *
 * @returns {number} random id.
 */
const randomUid = () => Math.floor(Math.random() * 100000);

$.summernote.plugins = {
  ...$.summernote.plugins,
  frame,
  embedVideo,
  insertImage,
};

const ImageResize75PercentButton = function(context) {
  return $.summernote.ui
    .button({
      contents: '<span class="note-fontsize-10">75%</span>',
      tooltip: 'Resize Three-quarters',
      click: context.createInvokeHandler('editor.resize', '0.75'),
    })
    .render();
};

$.summernote.options = {
  ...$.summernote.options,
  fontSizes: ['8', '9', '10', '11', '12', '14', '15', '16', '18', '20', '24', '25', '30', '36'],
  colors: [
    ['#79858b', '#cccccc80', '#222228', '#cccccc', '#F5F5F5', '#ec5f59', '#3e72b7', '#5bc55b'],
  ],
  colorsName: [
    ['Secondary', 'LightGrey', 'Dark', 'MediumLight', 'Light', 'Analyst', 'Edge', 'Green'],
  ],
  prettifyHtml: false,
  codemirror: {
    mode: 'text/html',
    lineWrapping: true,
    lineNumbers: true,
  },
  styleTags: ['p', 'h1', 'h2', 'h3', 'h4'],
};

$.summernote.options.buttons['imageSize75'] = ImageResize75PercentButton;
$.summernote.options.popover.image = [
  ['imagesize', ['imageSize100', 'imageSize75', 'imageSize50', 'imageSize25']],
  ['float', ['floatLeft', 'floatRight', 'floatNone']],
  ['remove', ['removeMedia']],
];

class ReactSummernote extends Component {
  /**
   * @param {Object} props New props.
   */
  constructor(props) {
    super(props);

    this.uid = `react-summernote-${randomUid()}`;
    this.editor = {};
    this.noteEditable = null;
    this.notePlaceholder = null;

    this.onInit = this.onInit.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);
    this.onMediaDelete = this.onMediaDelete.bind(this);

    this.focus = this.focus.bind(this);
    this.isEmpty = this.isEmpty.bind(this);
    this.reset = this.reset.bind(this);
    this.replace = this.replace.bind(this);
    this.disable = this.disable.bind(this);
    this.enable = this.enable.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.insertImage = this.insertImage.bind(this);
    this.insertNode = this.insertNode.bind(this);
    this.insertText = this.insertText.bind(this);

    ReactSummernote.focus = this.focus.bind(this);
    ReactSummernote.isEmpty = this.isEmpty.bind(this);
    ReactSummernote.reset = this.reset.bind(this);
    ReactSummernote.replace = this.replace.bind(this);
    ReactSummernote.disable = this.disable.bind(this);
    ReactSummernote.enable = this.enable.bind(this);
    ReactSummernote.toggleState = this.toggleState.bind(this);
    ReactSummernote.insertImage = this.insertImage.bind(this);
    ReactSummernote.insertNode = this.insertNode.bind(this);
    ReactSummernote.insertText = this.insertText.bind(this);
  }

  /**
   * @description Sets options.
   */
  componentDidMount() {
    const options = this.props.options || {};
    const codeview = this.props.codeview;
    // const codeviewCommand = codeview ? 'codeview.activate' : 'codeview.deactivate';
    options.callbacks = this.callbacks;

    this.editor = $(`#${this.uid}`);
    this.editor.summernote(options);
    if (codeview) {
      this.editor.summernote('codeview.activate');
    }
  }

  /***
   * @returns {bool} update state.
   */
  shouldComponentUpdate() {
    return false;
  }

  /**
   *@description destroy summernote.
   */
  componentWillUnmount() {
    if (this.editor.summernote) {
      this.editor.summernote('destroy');
    }
  }

  /**
   *@description init summernote.
   */
  onInit() {
    const { disabled, onInit, zIndex } = this.props;

    const $container = this.editor.parent();
    this.noteEditable = $container.find('.note-editable');
    this.notePlaceholder = $container.find('.note-placeholder');
    this.noteToolbar = $container.find('.note-toolbar');
    this.noteToolbar.css('z-index', zIndex);

    if (typeof disabled === 'boolean') {
      this.toggleState(disabled);
    }

    if (typeof onInit === 'function') {
      onInit({
        summernote: this.editor.summernote.bind(this.editor),
        focus: this.focus,
        isEmpty: this.isEmpty,
        reset: this.reset,
        replace: this.replace,
        disable: this.disable,
        enable: this.enable,
        insertImage: this.insertImage,
        insertNode: this.insertNode,
        insertText: this.insertText,
      });
    }
  }

  /**
   *@param {Object} images file
   *
   *@description upload image event.
   */
  onImageUpload(images) {
    const { onImageUpload } = this.props;

    if (onImageUpload) {
      onImageUpload(images, this.insertNode);
    }
  }

  /**
   *@param {Object} $target img tag
   *
   *@description remove image event.
   */
  onMediaDelete($target) {
    const { onImageRemove } = this.props;

    if (onImageRemove) {
      onImageRemove($target[0].currentSrc);
    }
  }

  /**
   *@description set focus of summernote.
   */
  focus() {
    this.editor.summernote('focus');
  }

  /**
   *@returns {bool} isEmpty summernote.
   */
  isEmpty() {
    return this.editor.summernote('isEmpty');
  }

  /**
   *@description reset summernote.
   */
  reset() {
    this.editor.summernote('reset');
  }

  /**
   *@param {node} content html.
   */
  replace(content) {
    const { noteEditable, notePlaceholder } = this;
    const prevContent = noteEditable.html();
    const contentLength = content.length;

    if (prevContent !== content) {
      if (this.isEmpty() && contentLength > 0) {
        notePlaceholder.hide();
      } else if (contentLength === 0) {
        notePlaceholder.show();
      }
      noteEditable.html(content);
    }
  }

  /**
   *@description disable summernote.
   */
  disable() {
    this.editor.summernote('disable');
  }

  /**
   *@description enable summernote.
   */
  enable() {
    this.editor.summernote('enable');
  }

  /**
   *@param {bool} disabled state
   *
   *@description set disable state of summernote.
   */
  toggleState(disabled) {
    if (disabled) {
      this.disable();
    } else {
      this.enable();
    }
  }

  /**
   *@param {string} url state
   *@param {callback} filenameOrCallback state
   */
  insertImage(url, filenameOrCallback) {
    this.editor.summernote('insertImage', url, filenameOrCallback);
  }

  /**
   *@param {node} node state
   */
  insertNode(node) {
    this.editor.summernote('insertNode', node);
  }

  /**
   *@param {string} text state
   */
  insertText(text) {
    this.editor.summernote('insertText', text);
  }

  /**
   *@returns {Object} callbacks
   */
  get callbacks() {
    const props = this.props;

    return {
      onInit: this.onInit,
      onEnter: props.onEnter,
      onFocus: props.onFocus,
      onBlur: this.onBlur,
      onKeyup: props.onKeyUp,
      onKeydown: props.onKeyDown,
      onPaste: props.onPaste,
      onChange: this.onChange,
      onImageUpload: this.onImageUpload,
      onMediaDelete: this.onMediaDelete,
    };
  }

  onBlur = (...params) => {
    const content = jsBeautify.html(this.props.value);
    this.editor.html(content);

    this.props.onChange(content, this.editor);
    if (this.props.onBlur) {
      this.props.onBlur(...params);
    }
  };

  onChange = (value, ...props) => {
    if (this.isEmpty()) {
      value = '';
    }

    this.props.onChange(value, ...props);
  };
  /**
   * @returns {JSX.Element} ReactSummernote component.
   */
  render() {
    const { value, defaultValue, className } = this.props;
    const html = value || defaultValue;

    return (
      <div className={className} id={this.props.name}>
        <div id={this.uid} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  }
}

ReactSummernote.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  zIndex: PropTypes.number,
  codeview: PropTypes.bool,
  className: PropTypes.string,
  options: PropTypes.object,
  disabled: PropTypes.bool,
  onInit: PropTypes.func,
  onEnter: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func,
  onChange: PropTypes.func,
  onImageUpload: PropTypes.func,
  onImageRemove: PropTypes.func,
  name: PropTypes.string,
};

export default ReactSummernote;
